import * as React from "react";
import { seoData } from "../data/seo";
import { navData } from "../data/nav";
import { accordionData, accordionLabel } from "../data/accordion";

import Seo from "../components/seo";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentFloorPlans from "../components/content-floor-plans";
import NewComposition from "../components/composition/test";

const BeachTowerFloorPlans = () => {
  return (
    <main>
      <Seo page={seoData.beachTowerFloorPlans} />

      <section>
        <ContentFloorPlans
          label={accordionLabel.beachTowerAmenities.label}
          btData={accordionData.beachTowerFloorPlans}
          mtData={accordionData.marinaTowerFloorPlans}
          type={"mt"}
        />
      </section>

      <section>
      <ContentNextPage
        type={'bg-full'}
        nextLink={navData.film.link}
        nextTitle={navData.film.next}
        vertAlign={'center'}
        mobHeight={"40vh"}
      />
        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"93vw"}
          height={"600px"}
          mobBreakpoint={"phoneXL"}
          mobTop={"-10%"}
          mobRight={"90vw"}
          mobHeight={"100%"}
        />
        <NewComposition
          shape={"triangle"}
          top={"0"}
          right={"0"}
          height={"100%"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={"80%"}
        />
      </section>
      <Footer />
    </main>
  );
};

export default BeachTowerFloorPlans;
